// actions/audioActions.ts
import {textToSpeechPost } from './types'

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const START_POLLING_REQUEST = 'START_POLLING_REQUEST';
export const POLLING_SUCCESS = 'POLLING_SUCCESS';
export const POLLING_FAILURE = 'POLLING_FAILURE';

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';

export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS ='LOGIN_SUCCESS';
export const LOGIN_FAILED ='LOGIN_FAILED';
export const CLEAN_UP_ACTION ='CLEAN_UP_ACTION';
export const DOWNLOAD_PLAYABLE_FILE_REQUEST = 'DOWNLOAD_PLAYABLE_FILE_REQUEST';
export const DOWNLOAD_PLAYABLE_FILE_SUCCESS ='DOWNLOAD_PLAYABLE_FILE_SUCCESS';
export const DOWNLOAD_PLAYABLE_FILE_FAILURE ='DOWNLOAD_PLAYABLE_FILE_FAILURE';

// text to speech
export const CONVERT_TEXT_TO_SPEECH_REQUEST ='CONVERT_TEXT_TO_SPEECH_REQUEST';
export const CONVERT_TEXT_TO_SPEECH_SUCCESS ='CONVERT_TEXT_TO_SPEECH_SUCCESS';
export const CONVERT_TEXT_TO_SPEECH_FAILURE ='CONVERT_TEXT_TO_SPEECH_FAILURE';

// Action creators
export const uploadFileRequest = (file: File) => ({ type: UPLOAD_FILE_REQUEST, file });
export const startPollingRequest = (jobId: string) => ({ type: START_POLLING_REQUEST, jobId });
export const downloadPlayableFileRequest = (fileType: string, jobId: string) => ({ type: DOWNLOAD_PLAYABLE_FILE_REQUEST, fileType, jobId });
export const downloadFileRequest = (fileType: string, jobId: string) => ({ type: DOWNLOAD_FILE_REQUEST, fileType, jobId });

export const convertTextToSpeechRequest = (payload: textToSpeechPost ) => ({ type: CONVERT_TEXT_TO_SPEECH_REQUEST, payload });


export const loginRequest = () => ({type: LOGIN_REQUEST });
export const cleanUp= () => ({type: CLEAN_UP_ACTION});
