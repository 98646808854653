// src/components/Footer.tsx
import React from 'react';
import './Footer.css';

const Footer: React.FC = () => {
  
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer">
     <p> splitt.one | © {currentYear} </p>
    </div>
  );
};

export default Footer;
// export {};
