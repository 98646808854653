import React, { useState } from 'react';
import { DefaultButton, PrimaryButton, TextField, Label, Spinner, MessageBar, MessageBarType, Modal, IconButton } from '@fluentui/react';
import { Stack } from '@fluentui/react/lib/Stack';
import { Image } from '@fluentui/react/lib/Image';

const GifGenCompFluent: React.FC = () => {
  const [selectedFiles, setSelectedFiles] = useState<File[]>([]);
  const [gifUrl, setGifUrl] = useState<string | null>(null);
  const [duration, setDuration] = useState<number>(500);
  const [loading, setLoading] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false); // State for modal

  const handleFileChange2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      const files = Array.from(event.target.files);
      const resizedFiles: File[] = [];
  
      files.forEach((file) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
  
        reader.onload = (readerEvent) => {
          if (readerEvent.target && typeof readerEvent.target.result === 'string') {
            const img = document.createElement('img') as HTMLImageElement;

            img.src = readerEvent.target.result;
  
            img.onload = () => {
              const canvas = document.createElement('canvas');
              const ctx = canvas.getContext('2d');
              
              const maxWidth = 300; // Adjust size as needed
              const maxHeight = 450; // Adjust size as needed
  
              let width = img.width;
              let height = img.height;
  
              if (width > height) {
                if (width > maxWidth) {
                  height = Math.round((height *= maxWidth / width));
                  width = maxWidth;
                }
              } else {
                if (height > maxHeight) {
                  width = Math.round((width *= maxHeight / height));
                  height = maxHeight;
                }
              }
  
              canvas.width = maxWidth;
              canvas.height = maxHeight;
  
              if (ctx) {
                ctx.clearRect(0, 0, maxWidth, maxHeight);
                ctx.drawImage(img, (maxWidth - width) / 2, (maxHeight - height) / 2, width, height);
  
                canvas.toBlob((blob) => {
                  if (blob) {
                    resizedFiles.push(new File([blob], file.name, { type: file.type }));
                    if (resizedFiles.length === files.length) {
                      setSelectedFiles(resizedFiles);
                    }
                  }
                }, file.type);
              }
            };
          }
        };
      });
    }
  };
  

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      setSelectedFiles(Array.from(event.target.files));
      setErrorMessage(null);
    }
  };

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    
    const formData = new FormData();
    selectedFiles.forEach((file) => {
      formData.append('images', file);
    });

    setLoading(true);
    setErrorMessage(null);

    try {
      const response = await fetch(`https://us-central1-myaudioproject-38e8e.cloudfunctions.net/gif-function?duration=${duration}`, {
        method: 'POST',
        body: formData,
        headers: {
            'Accept': 'application/json',
        },
      });

      if (response.ok) {
        const blob = await response.blob();
        const url = URL.createObjectURL(blob);
        setIsModalOpen(true);
        setGifUrl(url);
      } else {
        throw new Error('Failed to create GIF: ' + response.statusText);
      }
    } catch (error) {
      setErrorMessage(error instanceof Error ? error.message : 'An unknown error occurred');
    } finally {
      setLoading(false);
    }
  };

  return (
    <Stack tokens={{ childrenGap: 20, padding: 20 }}>
      <h1>Gif Generator</h1>
      <form onSubmit={handleSubmit}>
  <Stack tokens={{ childrenGap: 15, padding: 20 }}
   style={{ backgroundColor: '#1c2833', 
    borderRadius:  '10px',
  
  
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)' }}>
   <label htmlFor="file-upload" 
   style={{  display: 'block',
    marginBottom: '10px',
    cursor: 'pointer',
    padding: '15px',
    border: '1px solid #d1d1d1',
    borderRadius: '5px',
    textAlign: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.3s ease, box-shadow 0.3s ease', // Add transition for smooth hover effect
  }}
  onMouseEnter={(e) => (e.currentTarget.style.boxShadow = '0 6px 12px rgba(230, 190, 40, 0.2)')}
  onMouseLeave={(e) => (e.currentTarget.style.boxShadow = '2px 4px 8px rgba(250, 60, 60, 0.3)')}
  >
  Upload Files
  <input
    id="file-upload"
    type="file"
    multiple
    accept="image/*"
    onChange={handleFileChange2}
    style={{ display: 'none' }}
  />
</label>

    <Stack horizontal tokens={{ childrenGap: 10 }} 
    verticalAlign="center">
      <Label htmlFor="duration" style={{color: 'white'}}>Frame Duration (ms): </Label>
      <TextField
        type="number"
        id="duration"
        value={duration.toString()}
        onChange={(e) => setDuration(Number((e.target as HTMLInputElement).value))}
        min="100"
        style={{
          width: '80px',
          borderRadius: '5px',
          padding: '5px',
          borderColor: '#0078d4',
        }}
      />
    </Stack>
   
<Stack horizontal wrap tokens={{ childrenGap: 10}}>
        {selectedFiles.map((file, index) => (
          <Image
            key={index}
            src={URL.createObjectURL(file)}
            alt={`Selected ${index}`}
            width={100}
            height={200}
            style={{ margin: '5px' }}
          />
        ))}


      </Stack>

      {/* {gifUrl && (
        <Stack tokens={{ padding: 20 }}>
          <h2>Generated GIF</h2>
          <Image src={gifUrl} alt="Generated GIF" width={50} />
        </Stack>
      )} */}

      <DefaultButton
      type="submit"
      text="Create GIF"
      style={{
        backgroundColor: '#0078d4',
        color: '#fff',
        padding: '10px 20px',
        borderRadius: '5px',
        boxShadow: '0 2px 5px rgba(0,0,0,0.2)',
      }}
    />
      {loading && <Spinner label="Creating GIF..." />}
       {/* Modal to display the generated GIF */}
       <Modal
        isOpen={isModalOpen}
        onDismiss={() => setIsModalOpen(false)}
        isBlocking={false}
        containerClassName="ms-modalExample-container"
        styles={{
            main: {
              backgroundColor: '#1c2833', // Change this to the desired background color
              padding: '20px',
              borderRadius: '10px',
              color:'white'
            },
          }}
      >
        <div className="ms-modalExample-header">
          <span>Generated GIF</span>
          <PrimaryButton
      styles={{ root: { position: 'absolute', padding:'1px', margin:'5px', top: '3px', right: '10px', backgroundColor: '#1c2833' } }}
    //   iconProps={{ iconName: 'Cancel', styles: { root: { fontSize: '10px', fontWeight: 'bold' } } }} // Make the "Cancel" icon bold and larger

            onClick={() => setIsModalOpen(false)}
          > Close
           </PrimaryButton>
        </div>
        <div className="ms-modalExample-body"
         style={{margin:'5px',
            marginBottom:'5px',
          padding:'15px'}}>
          {gifUrl && <Image src={gifUrl}
           alt="Generated GIF"
            width={300}
            height={450} />}
        </div>
      </Modal>


  </Stack>
</form>
     
      {errorMessage && <MessageBar messageBarType={MessageBarType.error}>{errorMessage}</MessageBar>}
    
    
    </Stack>
  );
};

export default  GifGenCompFluent 
;
