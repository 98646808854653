// src/components/TopBar.tsx
import React from 'react';
import './TopBar.css';

const TopBar: React.FC = () => {
  return (
    <div className="top-bar">
      
      <img src={`${process.env.PUBLIC_URL}/splittone.ico`} alt="SplitTone Logo" className="logo" />

      <h1> splitt.one </h1>
    </div>
  );
};

export default TopBar;
