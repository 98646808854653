// sagas/audioSagas.ts
import { call, put, takeEvery,take, delay, all } from 'redux-saga/effects';
import { UPLOAD_FILE_REQUEST,CLEAN_UP_ACTION,LOGIN_SUCCESS,DOWNLOAD_PLAYABLE_FILE_REQUEST,
  DOWNLOAD_PLAYABLE_FILE_FAILURE,DOWNLOAD_PLAYABLE_FILE_SUCCESS,
   UPLOAD_FILE_SUCCESS, UPLOAD_FILE_FAILURE, START_POLLING_REQUEST, POLLING_SUCCESS,
    POLLING_FAILURE, DOWNLOAD_FILE_REQUEST,
    DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE, LOGIN_FAILED, LOGIN_REQUEST, CONVERT_TEXT_TO_SPEECH_SUCCESS, CONVERT_TEXT_TO_SPEECH_FAILURE, CONVERT_TEXT_TO_SPEECH_REQUEST } from '../actions/audioActions';
import { SagaIterator } from 'redux-saga';
import axios, { AxiosResponse } from 'axios';

const allowedTypes = ['audio/mpeg', 'audio/wav'];
const saveToken = (token: string) => {
    const expiryTime = new Date().getTime() + 1800 * 1000; // 1800 seconds (30 minutes)
   // const tokenData = { token, expiryTime };
    localStorage.setItem('token', token);
    localStorage.setItem('token_exp', expiryTime.toString());
};

const getToken = () => {
    const tokenData = localStorage.getItem('token');
    if (!tokenData) return null;

    var storedString = localStorage.getItem('token_exp');
    if (!storedString) return null;

    const expiryTime = parseInt(storedString, 10);
    console.log('expiry:', expiryTime); // Outputs: 42


    //const { token, expiryTime } = tokenData;
    if (new Date().getTime() > expiryTime) {
        localStorage.removeItem('token');
        localStorage.removeItem('token_exp');
        return null;
    }

    return tokenData;
};



const uploadFile = (file: File, endpoint: string, onUploadProgress: (progressEvent: any) => void) => {
    const formData = new FormData();
    formData.append('file', file);

    return axios.post(endpoint, formData, {
        onUploadProgress,
         headers:{
            'Content-Type': 'multipart/form-data',
            'Authorization': getToken()
        }
    });
};



function* loginSaga(action: any) : SagaIterator{
    try {
        const baseUrl = process.env.REACT_APP_MEDIA_API_BASE_URL;
        const createSessionEndpoint = process.env.REACT_APP_CREATE_SESSION_API_URL;
        const fullUrl = `${baseUrl}${createSessionEndpoint}`;
      // Call the API to log in
      const response = yield call(axios.post, fullUrl,{
        headers:{
            'Content-Type': 'multipart/form-data',
            'Authorization': getToken()
        }}
      );
      console.log('token_inlogin', response.data.token);
  
      // Save the token in localStorage on successful login
      if (response.data.token) {
       //localStorage.setItem('token', response.data.token);
        saveToken(response.data.token);
      }
  
      // Dispatch login success action
      yield put({ type: LOGIN_SUCCESS, token: response.data.token });
    } catch (error) {
        yield put({ type: LOGIN_FAILED });
    }
  }

// File upload saga
function* uploadFileSaga(action:any): SagaIterator {
  try {
    const maxSize = 14 * 1024 * 1024; // 14 MB
    let token = getToken();
    console.log('token: ', token)
     // Trigger login if no token exists
     
    yield put({ type: LOGIN_REQUEST });
  
        // Wait for LOGIN_SUCCESS or LOGIN_FAILURE
    const loginaction = yield take([LOGIN_SUCCESS, LOGIN_FAILED]);

    if (loginaction.type === LOGIN_FAILED)  {
          // Handle login failure
          throw new Error('Failed. Please try again. Er:ElogD675');
    }      
    
    token = getToken();        

    const formData = new FormData();
    formData.append('file', action.file);

    if(action.file.size > maxSize){
        console.log('file_type', action.file.size)      ;
        throw new Error('File size exceeds the 10MB limit. ')

    }
    if (!allowedTypes.includes(action.file.type)) {  
        console.log('file_type', action.file.type)      ;
        throw new Error('File type not allowed')
    }

    const baseUrl = process.env.REACT_APP_MEDIA_API_BASE_URL;
        const uploadEndpoint = process.env.REACT_APP_UPLOAD_API_URL;
        const fullUrl = `${baseUrl}${uploadEndpoint}`;
    
    let response = yield call(axios.post, fullUrl, formData, {         
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': token
        },
      });                     

    // const response = yield call(uploadFile, action.file, baseUrl, action.onUploadProgress);
    
    //const data = yield response.json();
    yield put({ type: UPLOAD_FILE_SUCCESS, jobId:  response.data.job_id });
    yield put({ type: START_POLLING_REQUEST, jobId:  response.data.job_id });
  } catch (error) {
    //    console.log('fail.. here with.. ', error.data.response);
    if (error instanceof Error)        
    yield put({ type: UPLOAD_FILE_FAILURE, error: error.message });
  }
}

// Polling saga
function* pollingSaga(action:any) :SagaIterator{
  try {
    while (true) {
      const url = `${process.env.REACT_APP_MEDIA_API_BASE_URL}${process.env.REACT_APP_GET_MEDIA_SPLEETER_STATUS_API_URL}${action.jobId}`;
      const response = yield call(axios.get, url, 
        {
            headers: {                
                'Authorization': getToken()
              },

        });
      //const data = yield response.json();
      if (response.data.status === 'COMPLETED') {
        yield put({ type: POLLING_SUCCESS, audioFiles: response.data });
        break;
      }
      if (response.data.status !== 'PROCESSING') {
        throw new Error('Processing failed');
      }
      yield delay(5000); // Poll every 5 seconds
    }
  } catch (error) {
    if (error instanceof Error){
        yield put({ type: POLLING_FAILURE, error: error.message });
    }
   
  }
}

// Download file saga
function* downloadPlayableFileSaga(action:any):SagaIterator {
  try {
    const url = `${process.env.REACT_APP_MEDIA_API_BASE_URL}${process.env.REACT_APP_DOWNLOAD_API_URL}${action.jobId}&file_name=${action.fileType}&play=1`;
    const response = yield call(axios.get, url, {
        responseType: 'blob', // Set the response type to blob
        headers :{
            'Authorization': getToken()
        }
      });

    //const response = yield call(axios.get, url);
   //const blob = yield response.blob();
    const fileUrl = URL.createObjectURL(response.data);
    //console.log('download_file', fileUrl);
    yield put({ type: DOWNLOAD_PLAYABLE_FILE_SUCCESS, fileType: action.fileType, fileUrl });
  } catch (error) {
    if (error instanceof Error)
    yield put({ type: DOWNLOAD_PLAYABLE_FILE_FAILURE, error: error.message });
  }
}

// Download file saga
function* downloadFileSaga(action:any):SagaIterator {
    try {
      const url = `${process.env.REACT_APP_MEDIA_API_BASE_URL}${process.env.REACT_APP_DOWNLOAD_API_URL}${action.jobId}&file_name=${action.fileType}`;
    //   const response = yield call(axios.get, url, {
    //      // responseType: 'blob', // Set the response type to blob
    //       headers :{
    //           'Authorization': getToken()
    //       }
    //     });
  
      //const response = yield call(axios.get, url);
     //const blob = yield response.blob();
    //   const fileUrl = URL.createObjectURL(response.data);
      console.log('download_file_downloader', url);
      yield put({ type: DOWNLOAD_FILE_SUCCESS, fileType: action.fileType, url });
    } catch (error) {
      if (error instanceof Error)
      yield put({ type: DOWNLOAD_FILE_FAILURE, error: error.message });
    }
  }

// Download file saga
function* convertTextToSpeechSaga(action:any):SagaIterator {
  try {
    const url = `${process.env.REACT_APP_MEDIA_API_BASE_URL}${process.env.REACT_APP_TEXT_TO_SPEECH_API_URL}`;
    
    const response = yield call(axios.post, url, action.payload, {
        responseType: 'blob', // Set the response type to blob
        headers :{
            'Authorization': getToken()
        }
      });
  
    const playUrl = URL.createObjectURL(response.data);
    console.log('tts playurl: ', playUrl);

    yield put({ type: CONVERT_TEXT_TO_SPEECH_SUCCESS, playUrl: playUrl });

  } catch (error) {
    if (error instanceof Error)
    yield put({ type: CONVERT_TEXT_TO_SPEECH_FAILURE, error: error.message });
  }
}

export function* audioSagas() {
  yield takeEvery(UPLOAD_FILE_REQUEST, uploadFileSaga);
  yield takeEvery(START_POLLING_REQUEST, pollingSaga);
  yield takeEvery(DOWNLOAD_PLAYABLE_FILE_REQUEST, downloadPlayableFileSaga);
  yield takeEvery(DOWNLOAD_FILE_REQUEST, downloadFileSaga);
  yield takeEvery(LOGIN_REQUEST, loginSaga);
  yield takeEvery(CONVERT_TEXT_TO_SPEECH_REQUEST, convertTextToSpeechSaga);
  //yield takeEvery(CLEAN_UP_ACTION, cleanUp);
}



export default function* rootSaga() {
    yield all([
      audioSagas(), // Run your audio sagas
      // other sagas can be added here
    ]);
  }
