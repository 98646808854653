// components/AudioSplitter.tsx
import React, { useEffect, useRef, useState } from 'react';
import { Text, PrimaryButton, DefaultButton, Label, Spinner, Stack } from '@fluentui/react';
import { useDispatch, useSelector } from 'react-redux';
import { uploadFileRequest, downloadFileRequest,downloadPlayableFileRequest, loginRequest, cleanUp } from '../actions/audioActions';
import './AudioSplitter.css';

const AudioSplitter2: React.FC = () => {
  const dispatch = useDispatch();
  const { uploadStatus,processingStatus, completionMessage,processing,mediaLoadingMessage, audioFiles, downloadUrl,disableDownloadButton, playUrl, error } = useSelector((state: any) => state);

  const audioRef = useRef<HTMLAudioElement>(null);
  const selectedButton = useRef<string>('vocal');
  //const playableUrl = useRef<string>('');
  const [showOverlay, setShowOverlay] = useState<boolean>(false); // To show/hide the overlay window
  const [audioBoxColor, setAudioBoxColor] = useState<string>('lightblue');


  const handleFileUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    setShowOverlay(true);
    const file = event.target.files?.[0];
    if (file) {
      dispatch(uploadFileRequest(file));
    }
  };

  const handlePlay = (selected_button: string) => {
    selectedButton.current = selected_button;
    dispatch(downloadPlayableFileRequest(selectedButton.current, audioFiles.job_id));

    if (selected_button === 'vocal') {
        setAudioBoxColor('#3d8bd4');
      } else if (selected_button === 'music') {
        setAudioBoxColor('#2a3c4d'); // Change this to your preferred pink shade
      }
   
  };


  const handleDownload = () => {
    const blobUrl = playUrl;//'blob:http://localhost:3000/7f08f392-1c8d-4689-abf9-232229d7bf88'; // Replace with your actual blob URL
    const link = document.createElement('a');
    link.href = blobUrl;
    link.download =  selectedButton.current; // Specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  const handleCloseOverlay =()=>{
    setShowOverlay(false);        
    dispatch(cleanUp());
  }


 // Update the audio source when playUrl changes
 useEffect(() => {
    console.log('playurl_updated_1', playUrl);
    if (audioRef.current && playUrl) {
        console.log('playurl_updated', playUrl);
      audioRef.current.src = playUrl; // Update the audio source
      audioRef.current.load(); // Reload the audio element to reflect the new source
      audioRef.current.play();
    }
  }, [playUrl]); // Dependency array: run this effect when playUrl changes



  return (
    <div className="audio-splitter">
      <h4>Upload your media file to start splitting vocals and instrumentals. 
        <br/> <br/>
        The first 60 seconds of your file will be split. All files will be automatically deleted from our servers after 5 minutes.  🎧🚀</h4>
<br/>

      {!showOverlay && (
                <label htmlFor="file-upload" className="upload-button">Upload</label>
            )}
            <input
                id="file-upload"
                type="file"
                onChange={handleFileUpload}
                style={{ display: 'none' }}
            />

      {/* <input id="file-upload" type="file" onChange={handleFileUpload} /> */}
      { showOverlay  && (
                <div className="overlay">
                      <button className="close-btn" onClick={handleCloseOverlay}>X</button>
       {/* Grouping statuses */}
       <div className="status-messages">
          <p><strong>Upload Status:</strong> {uploadStatus}</p>
          <p><strong>Processing Status:</strong> {processingStatus}</p> 
          <br/><br/>
          <p><strong>{completionMessage}</strong> </p>
        </div>

      <p style={{color: 'red'}}>{error}      </p>
      <p>{mediaLoadingMessage}</p>

      {processing && (
                        <div className="circular-progress">
                            <div className="spinner"></div>
                            <p>Processing...</p>
                        </div>
                    )}

      {audioFiles && (
        <div className="footer-container">
          <button className={`button2 vocal-button2`}  onClick={() => handlePlay('vocal')}>Vocal</button>
          <button  className={`button2 music-button2`} onClick={() => handlePlay('music')}>Music</button>
          {/* <audio ref={audioRef} src={playableUrl.current} controls /> */}
          
          <div className='audio-box2' >
         
          <audio ref={audioRef} src={playUrl} controls />
             <div className='audio-box2-underline' style={{ backgroundColor: audioBoxColor }}></div>
          </div>
          
          <button className='button2' disabled={disableDownloadButton} style={{ backgroundColor: audioBoxColor }} onClick={handleDownload}>Download</button>
        </div>
      )} 

</div>
      )}

    </div>
  ); 
};



export default AudioSplitter2;
