// src/App.tsx
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Sidebar from './components/Sidebar';
import TopBar from './components/TopBar';
import Footer from './components/Footer';
import AudioSplitter from './components/AudioSplitter';
import AudioSplitter2 from './components/AudioSplitter2';
import FormatConverter from './components/FormatConverter';
import './styles/App.css';
import AudioControls from './components/AudioControls';
import AboutUs from './components/AboutUs';
import PrivacyPolicy from './components/Privacy';
import TextToSpeech from './components/TextToSpeech';
import TextToSpeechForm from './components/TextToSpeechFormFluent';
import GifGenComp from './components/GifGenComp';
import GifGenCompFluent from './components/GifGenCompFluent' 

const App: React.FC = () => {
  return (
    <Router>
      <div className="app">
        <TopBar />
        <Sidebar />
        <div className="main-content">
          <Routes>
            <Route path="/" element={<AudioSplitter2 />} />
            <Route path="/AboutUs" element={<AboutUs />} />
            <Route path="/converter" element={<FormatConverter />} />
            <Route path="/privacy" element={<PrivacyPolicy />} />
            <Route path="/textToSpeech" element={<TextToSpeech />} />
            {/* <Route path="/create-gif" element={<GifGenComp />} /> */}
            <Route path="/create-gif" element={<GifGenCompFluent />} />
           
          </Routes>
        </div>
        <Footer />
      </div>
    </Router>
  );
};

export default App;
