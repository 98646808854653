import React from 'react';
import './AboutUs.css'; // Assuming you will create a separate CSS file for styling

const AboutUs: React.FC = () => {
    return (
        <div className="about-us-container">
            <h1>About Us</h1>
            <p>
                Welcome to <strong>splitt.one</strong>! <br/><br/>
                <strong>splitt.one</strong> is the brainchild of one passionate developer who embarked on this project as a fun side endeavor. With a love for music and a knack for coding, this solo developer crafted SplitTone to enhance your music experience by separating vocals and instrumentals in your favorite tracks.<br/><br/>
                This project isn't just about technology—it's about the joy of creation and the thrill of transforming how you enjoy your tunes. Every line of code was written with a mix of expertise and a deep passion for music.<br/><br/>
                Here at splitt.one, we believe in the magic of innovation and the power of a single person's vision. We hope you enjoy using the app as much as it was enjoyed being built.<br/><br/>
                Feel the beat, hear the difference, and dive into a new audio adventure with splitt.one! 🎧
            </p>
        </div>
    );
};

export default AboutUs;
export {};