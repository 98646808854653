// src/components/Sidebar.tsx
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { FaAddressBook, FaHome, FaMicrophone, FaTools, FaImage  } from 'react-icons/fa';

import { Tooltip } from 'react-tooltip'; // Use named import
import './Sidebar.css';
import { ActionButton } from '@fluentui/react';

const Sidebar: React.FC = () => {
  const [isOpen, setIsOpen] = useState<boolean>(false);

  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <div className="menu-button" onClick={toggleMenu} data-tooltip="Menu">
        &#9776; {/* Hamburger icon */}
        <Tooltip />        
      </div>
      <div className={`sidebar ${isOpen ? 'open' : 'closed'}`}>
        <ul>
        <li className="menu-item">
            <Link to="/" data-tooltip="Home">
              <FaHome className="menu-icon" size={24} />
          
              <Tooltip />
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/create-gif" data-tooltip="Gif generator">
            <FaImage className="menu-icon" size={24} />
              
              
              <Tooltip />
            </Link>
          </li>
        
          <li className="menu-item">
            <Link to="/textToSpeech" data-tooltip="Text To Speech Converter">
              <FaTools className="menu-icon" size={24} />
              
              <Tooltip />
            </Link>
          </li>
          <li className="menu-item">
            <Link to="/AboutUs" data-tooltip="About Us">
              <FaAddressBook className="menu-icon" size={24} />
            
              <Tooltip />
            </Link>
          </li>
        </ul>
      </div>
    </>
  );
};

export default Sidebar;
