// reducers/audioReducer.ts
import { UPLOAD_FILE_SUCCESS,UPLOAD_FILE_REQUEST,DOWNLOAD_PLAYABLE_FILE_FAILURE, 
  UPLOAD_FILE_FAILURE,DOWNLOAD_PLAYABLE_FILE_REQUEST,DOWNLOAD_PLAYABLE_FILE_SUCCESS, 
  START_POLLING_REQUEST,POLLING_SUCCESS, POLLING_FAILURE,DOWNLOAD_FILE_REQUEST, 
  DOWNLOAD_FILE_SUCCESS, DOWNLOAD_FILE_FAILURE, CLEAN_UP_ACTION,
   LOGIN_SUCCESS, CONVERT_TEXT_TO_SPEECH_SUCCESS, CONVERT_TEXT_TO_SPEECH_FAILURE, CONVERT_TEXT_TO_SPEECH_REQUEST } from '../actions/audioActions';

const initialState = {
  jobId: null,
  uploadStatus: '',
  processingStatus:'',
  completionMessage:'',
  mediaLoadingMessage:'',
  processing: false,
  audioFiles: null,
//   downloadUrl: {
//     vocal: '',
//     music: ''
//   },
  downloadUrl:'',
  playUrl: null,
  error: '',
  disableDownloadButton:true,
  textToSpeechPlayUrl:''
};

export const audioReducer = (state = initialState, action:any) => {
  switch (action.type) {
    case CLEAN_UP_ACTION:
        return initialState;
    case LOGIN_SUCCESS:
        return{
            ...state,

        };

            
    case UPLOAD_FILE_REQUEST:
        return {
            ...state,           
            uploadStatus: 'Uploading...',
            processingStatus:'Not Started',

        };
    case UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        jobId: action.jobId,
        uploadStatus: 'Completed',
      };
    case UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadStatus: 'Upload failed',
        error: action.error,
      };
    case START_POLLING_REQUEST:
        return{
            ...state,           
            processing: true,
            processingStatus:'Processing . .'

        };

    case POLLING_SUCCESS:
      return {
        ...state,
        audioFiles: action.audioFiles,
        processing: false,
       
        processingStatus: 'Completed.',
        completionMessage:'Splitting Complete! 🎉, Click on the Vocal or Music button to listen or download your tracks. 🎧✨'
        
      };
    case POLLING_FAILURE:
      return {
        ...state,
        processingStatus: 'Failed.',
        processing: false,
        error: action.error,
      };
    case DOWNLOAD_PLAYABLE_FILE_REQUEST:
        return {
            ...state,
            mediaLoadingMessage:'Loading Media ... ',

        };

    case DOWNLOAD_PLAYABLE_FILE_SUCCESS:
        return {
            ...state,
            playUrl: action.fileUrl,
            disableDownloadButton: false,
            mediaLoadingMessage:'',

        };

    case DOWNLOAD_PLAYABLE_FILE_FAILURE:
            return{
                ...state,
                mediaLoadingMessage:'Failed To Load Media. Please try again later.',

            };

    case DOWNLOAD_FILE_SUCCESS:
      return {
        ...state,
        downloadUrl: action.url,
        //mediaLoadingMessage:'',
        // downloadUrl: {
        //   ...state.downloadUrl,
        //   [action.fileType]: action.fileUrl,
        // },
       
        //playUrl: action.fileUrl,
      };
    case DOWNLOAD_FILE_FAILURE:
      return {
        ...state,
        error: action.error,
        mediaLoadingMessage:'Loading Media Failed.',
      };
    case CONVERT_TEXT_TO_SPEECH_REQUEST:
        return {
          ...state,
          textToSpeechPlayUrl : '',
          processing : true,
  
        };
    case CONVERT_TEXT_TO_SPEECH_SUCCESS:
      console.log('reducer, ' + action.playUrl);
      return {
        ...state,
        
        textToSpeechPlayUrl : action.playUrl,
        processing : false,

      };
    case CONVERT_TEXT_TO_SPEECH_FAILURE:
      return {
        ...state,
        processing : false,
        error:action.error,
        textToSpeechPlayUrl : ''

      };

    default:
      return state;
  }
};
