import React from 'react';

const PrivacyPolicy = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.title}>Privacy Policy for Amrot Translator</h1>
      <p style={styles.date}>Effective Date: October, 2024</p>
      <p style={styles.paragraph}>
       Amrot Translator ("us", "we", or "our") operates the Amrot Translator mobile application (the "App"). This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our App.
      </p>
      <h2 style={styles.heading}>1. Information We Do Not Collect</h2>
      <p style={styles.paragraph}>
        We want to assure you that we do not collect, store, or share any personal information or data while you use our App. Our App simply provides translation services from Amharic to Oromo and vice versa.
      </p>
      <h2 style={styles.heading}>2. Usage Data</h2>
      <p style={styles.paragraph}>
        While we do not collect personal information, we may collect non-personal usage data automatically, such as the type of device you are using, the operating system version, and the language preference. This data is used for internal purposes to improve our App's functionality and user experience.
      </p>
      <h2 style={styles.heading}>3. Cookies</h2>
      <p style={styles.paragraph}>
        Our App does not use cookies or similar tracking technologies to monitor user activity.
      </p>
      <h2 style={styles.heading}>4. Third-Party Services</h2>
      <p style={styles.paragraph}>
        Our App may use third-party services that may collect information used to identify you. However, we do not have access to or control over these services. We recommend reviewing their privacy policies.
      </p>
      <h2 style={styles.heading}>5. Links to Other Sites</h2>
      <p style={styles.paragraph}>
        Our App may contain links to third-party sites. We are not responsible for the privacy practices or the content of these sites. We encourage you to review the privacy policies of any third-party sites you visit.
      </p>
      <h2 style={styles.heading}>6. Changes to This Privacy Policy</h2>
      <p style={styles.paragraph}>
        We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page. You are advised to review this Privacy Policy periodically for any changes.
      </p>
      <h2 style={styles.heading}>7. Contact Us</h2>
      <p style={styles.paragraph}>
        If you have any questions about this Privacy Policy, please contact us:
      </p>
      <p style={styles.contact}>Email: davidlem6012@gmail.com</p>
     
    </div>
  );
};

const styles = {
  container: {
    padding: '16px',
    color: '#fff',
  },
  title: {
    fontSize: '24px',
    fontWeight: 'bold',
    marginBottom: '16px',
  },
  date: {
    fontSize: '16px',
    marginBottom: '16px',
  },
  heading: {
    fontSize: '20px',
    fontWeight: 'bold',
    marginTop: '16px',
    marginBottom: '8px',
  },
  paragraph: {
    fontSize: '16px',
    marginBottom: '8px',
    lineHeight: '24px',
  },
  contact: {
    fontSize: '16px',
    marginBottom: '8px',
    color: 'blue',
  },
};

export default PrivacyPolicy;
