import React, { useState ,useEffect, CSSProperties, useRef} from 'react';
import SpeechSynthesisRecorder from 'speech-synthesis-recorder';
import { blob } from 'stream/consumers';
import { useDispatch, useSelector } from 'react-redux';
import { convertTextToSpeechRequest, cleanUp } from '../actions/audioActions';
import {textToSpeechPost} from '../actions/types';

interface TTS {
  blob: () => Promise<Blob>;
  audioNode: HTMLAudioElement;
  utterance: SpeechSynthesisUtterance;
}

const TextToSpeech = () => {
const [text, setText] = useState('');
const [voice, setVoice] = useState<string>('');
const [downloadUrl, setDownloadurl] = useState<string>('');
const [voices, setVoices] = useState<string[]>([]);

// const [isLoading, setIsLoading] = useState(false);
const [isPlaying, setIsPlaying] = useState(false);

const dispatch = useDispatch();

const textToSpeechPlayUrl : string = useSelector((state: any) => state.textToSpeechPlayUrl);
const  processing: boolean  = useSelector((state: any) => state.processing);
const error: string = useSelector((state: any) => state.error);
const maxTextAreaChars = 450;


const handleDownload = () => {
      const link = document.createElement('a');
      link.href = downloadUrl;
      link.download = 'speech.mp3';//'speech.wav';
      link.click();

  };





//   const voices = window.speechSynthesis.getVoices();



useEffect(() => {
  const newVoices = ["Marta"];
  setVoices(newVoices);

  return () => {
   
    console.log(`Cleanup on count change or component unmount`);
    dispatch( cleanUp());
    setDownloadurl('');
   
  };

}, []);

useEffect(() => { 
  console.log('url: ' + textToSpeechPlayUrl);
  if(textToSpeechPlayUrl){
    setDownloadurl(textToSpeechPlayUrl);

    const audio = new Audio(textToSpeechPlayUrl);    
    audio.play();
  }

}, [textToSpeechPlayUrl]);


  const handlePlay = () => {

    const textToSpeechData: textToSpeechPost = {
      text: text,
      job_id: "12345"
    };
    
     dispatch(convertTextToSpeechRequest(textToSpeechData))
  
  };
  

  const handlePause = () => {
    window.speechSynthesis.pause();
    setIsPlaying(false);
  };

  const handleResume = () => {
    window.speechSynthesis.resume();
    setIsPlaying(true);
  };

  return (
    <div style={containerStyle}>
         <h1 style={headerText}>Coming Soon.</h1>
    </div>
//     <div style={containerStyle}>
//       <h1 style={headerText}>Text to Speech Converter</h1>

//   <div style={selectAndCountRow}>
//       <select
//         value={voice}
//         onChange={(e) => setVoice(e.target.value)}
//         style={selectStyle}
//       >      
//         {voices.map((v, index) => (
//           <option key={index} value={v}>{v}</option>
//         ))}
//       </select>    
      
//       <p style={charCountStyle}>{text.length}/{maxTextAreaChars}</p>
//   </div>
      
    
//     <textarea
//         value={text}
//         onChange={(e) => setText(e.target.value)}
//         placeholder="Enter text here"
//         style={textareaStyle}
//         maxLength={maxTextAreaChars}
        
//       />
     
    
    
    
//       {processing ? (
//         <p>Loading...</p>
//       ) : (
//         <button onClick={isPlaying ? handlePause : handlePlay} style={buttonStyle}>
//           {isPlaying ? 'Pause' : 'Play'}
//         </button>
//       )}
    
//       {downloadUrl &&(
//          <button onClick={handleDownload} style={buttonStyle}>Downaload</button>
//       )}

// {error && <p style={errorStyle}>{error}</p>}
//     </div>
  );
  
};

const containerStyle: CSSProperties = {
     position: 'relative',
    marginTop:'50px',
     padding:'15px',
     width: '90%',
     display: 'flex',
    flexDirection: 'column',
     alignItems: 'flex-start'
  };

const headerText: CSSProperties ={
   textAlign:'center',
   width:'80%',
   marginBottom:'50px',
   fontFamily:'cursive'
};

  const selectAndCountRow: CSSProperties ={
     display: 'flex',
     alignItems: 'center',
     width: '100%',
     justifyContent:'space-between'
    

  };

  const charCountStyle : CSSProperties ={
   
    margin:'0',
    flexBasis:'22%',
    color:'#c6c6c0',
    fontSize:'12px',
    fontStyle:'italic'
  
  }

  const selectStyle: CSSProperties = {
   
    marginBottom:'10px',
    marginRight: '20px',
     flexBasis:'20%',
  
   
    padding: '10px',
    fontSize: '16px',
    
  };
  
  
  const textareaStyle: CSSProperties = {
    backgroundColor: '#1e1e1e', // Dark background color
  color: '#ffffff',           // Light text color
  border: '1px solid #555',   // Border color
  padding: '10px',            // Padding for spacing
  fontSize: '16px',           // Font size
  resize: 'none'              ,
    width: '80%',
    height: '150px',
    margin: '10px 0',
    marginBottom:'20px'
    
   
  };
  
 
  
  const buttonStyle: CSSProperties = {
    position:'absolute',
    bottom: '-25px',
    right:'19%',
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    margin:'1px'
    
  };
  const downloadButtonStyle : CSSProperties = {
    right: '25%',
    position:'absolute',
    bottom: '-25px',
   
    padding: '10px 20px',
    fontSize: '16px',
    cursor: 'pointer',
    margin:'1px'

  };
  const errorStyle:CSSProperties ={
    color:'red'

  };

  

export default TextToSpeech;
